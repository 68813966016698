import React from 'react'
import { Link } from 'gatsby'
import { Flex, Button, Heading, Text } from 'theme-ui'
import { FaRegHandshake } from 'react-icons/fa'
import { GatsbyImage as Img } from 'gatsby-plugin-image'

const styles = {
  wrapper: {
    alignItems: `center`,
    flexDirection: `column`,
    bg: `background`,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'bannerBorder',
    borderRadius: `lg`,
    height: "93%",
    p: 4,
    backgroundImage: `url(https://blog-strapi-xtree.s3.amazonaws.com/20230109_Banner_Vertical_Blog_RA_892e425d28.jpg)`,
    backgroundRepeat: `no-repeat`,
  },
  heading: {
    color: `omegaDark`,
    wordSpacing: 500, //force line breaks
    svg: {
      color: `beta`,
      size: `icon.lg`,
      display: `block`,
      mb: 3,
    },
  },
  subheading: {
    color: `omegaDark`,
    fontWeight: `normal`,
  },
  list: {
    color: `omegaDark`,
    listStyle: `none`,
    m: 0,
    p: 0,
    li: {
      p: 0,
      my: 2,
    },
    'li:before': {
      content: `""`,
      display: `inline-block`,
      width: `icon.xs`,
      bg: `success`,
      borderRadius: `full`,
      size: `7px`,
      mr: `7px`,
    },
  },
  button: {
    //position: 'absolute',
    //bottom: '100px',
    mt: `auto`,
    fontWeight: '800',
    textDecoration: 'none',
    mb: "200%"
  },
}

const BannerVertical = () => (
  <a style={{textDecoration: 'none'}} href="https://www.xtree.com.vc/">
    <Flex sx={styles.wrapper}>
      <Button
        variant='banner'
        as={Link}
        to='https://www.xtree.com.vc/'
        sx={styles.button}
        aria-label='Clique aqui'
      >
        Clique aqui
      </Button>
    </Flex>
  </a>
)

export default BannerVertical
